import Styled from "@emotion/styled";
import { Box, TextField } from "@mui/material";

export const FormikStyledTextField = Styled(TextField)`
  background: #fff; 
  border-radius: 10px;
  width: 100%;
    & .MuiInputBase-root{
        height: 33px;
        margin-top: 5px;
        
        ${(props) =>
            props.redesign &&
            `
      height: 52px;
      `}
    }
`;

export const StyledCalendarPickerContainer = Styled(Box)`
  & .rdrDefinedRangesWrapper {
    display: none;
  }
  & .rdrInfiniteMonths {
    overflow: hidden;
  }
  & .react-datepicker {
    width: 100%;
    border: 0px;
    
  & .react-datepicker__month-container {
    width: 100% !important;
  }

  &.rdrDateRangePickerWrapper {
    display: none !important;
  }
  
  & .react-datepicker__day-name {
    height: 36px;
    width: 36px;
    border-radius: 50%;
    padding-top: 4px;
  }
  & .react-datepicker__week {
    & .react-datepicker__day {
    height: 36px;
    width: 36px;
    border-radius: 50%;
    padding-top: 4px;
  }
  }
  
  }

`;
