const ApiHostnameMap = {
    "localhost": "localhost", // default for unit tests

    "localhost:3000": "localhost:3012",
    "localhost.ca:3000": "localhost.ca:3012",
    "localhost.us:3000": "localhost.us:3012",
    "localhost.au:3000": "localhost.au:3012",

    "localhost:4000": "localhost:4012",
    "localhost.ca:4000": "localhost.ca:4012",
    "localhost.us:4000": "localhost.us:4012",
    "localhost.au:4000": "localhost.au:4012",

    "paylatertravel.ca": "plt-backend.paylatertravel.ca",
    "paylatertravel.com": "plt-backend.paylatertravel.com",
    "paylatertravel.com.au": "plt-backend.paylatertravel.com.au",

    "www.paylatertravel.ca": "plt-backend.paylatertravel.ca",
    "www.paylatertravel.com": "plt-backend.paylatertravel.com",
    "www.paylatertravel.com.au": "plt-backend.paylatertravel.com.au",

    "app.paylatertravel.ca": "plt-backend.paylatertravel.ca",
    "app.paylatertravel.com": "plt-backend.paylatertravel.com",
    "app.paylatertravel.com.au": "plt-backend.paylatertravel.com.au",

    "staging.paylatertravel.ca": "staging.plt-backend.paylatertravel.ca",
    "staging.paylatertravel.com": "staging.plt-backend.paylatertravel.com",
    "staging.paylatertravel.com.au": "staging.plt-backend.paylatertravel.com.au",

    "staging.app.paylatertravel.ca": "staging.plt-backend.paylatertravel.ca",
    "staging.app.paylatertravel.com": "staging.plt-backend.paylatertravel.com",
    "staging.app.paylatertravel.com.au": "staging.plt-backend.paylatertravel.com.au"
};

export const deriveAPIUrlFromCurrentLocation = (location) => {
    let currentHostname = `${location.hostname}${location.port ? `:${location.port}` : ""}`;
    const apiHostname = ApiHostnameMap[currentHostname];
    if (!apiHostname) {
        throw new Error("API Hostname not mapped for: " + window.location.hostname);
    }

    return `${location.protocol}//${apiHostname}/api`
};

const BookingManagementApiHostnameMap = {
    "localhost": "localhost", // default for unit tests

    "localhost:3000": "localhost:3013",
    "localhost.ca:3000": "localhost.ca:3013",
    "localhost.us:3000": "localhost.us:3013",
    "localhost.au:3000": "localhost.au:3013",

    "paylatertravel.ca": "booking-management.paylatertravel.ca",
    "paylatertravel.com": "booking-management.paylatertravel.com",
    "paylatertravel.com.au": "booking-management.paylatertravel.com.au",

    "www.paylatertravel.ca": "booking-management.paylatertravel.ca",
    "www.paylatertravel.com": "booking-management.paylatertravel.com",
    "www.paylatertravel.com.au": "booking-management.paylatertravel.com.au",

    "app.paylatertravel.ca": "booking-management.paylatertravel.ca",
    "app.paylatertravel.com": "booking-management.paylatertravel.com",
    "app.paylatertravel.com.au": "booking-management.paylatertravel.com.au",

    "staging.paylatertravel.ca": "staging.booking-management.paylatertravel.ca",
    "staging.paylatertravel.com": "staging.booking-management.paylatertravel.com",
    "staging.paylatertravel.com.au": "staging.booking-management.paylatertravel.com.au",

    "staging.app.paylatertravel.ca": "staging.booking-management.paylatertravel.ca",
    "staging.app.paylatertravel.com": "staging.booking-management.paylatertravel.com",
    "staging.app.paylatertravel.com.au": "staging.booking-management.paylatertravel.com.au"
};

export const deriveBookingManagementAPIUrlFromCurrentLocation = (location) => {
    const currentHostname = `${location.hostname}${location.port ? `:${location.port}` : ""}`;
    const apiHostname = BookingManagementApiHostnameMap[currentHostname];
    if (!apiHostname) {
        throw new Error("Booking Management API Hostname not mapped for: " + location.hostname);
    }

    return `${location.protocol}//${apiHostname}`
}
