export const formatDuration = (duration) =>
    duration.replace("PT", "").replace("D", "d ").replace("H", "h ").replace("M", "m");

export const titleCase = (str) => {
    str = str.toLowerCase();
    str = str.split(" ");
    for (let i = 0; i < str.length; i++) str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    return str.join(" ");
    // ["I'm", "A", "Little", "Tea", "Pot"].join(' ') => "I'm A Little Tea Pot"
};
