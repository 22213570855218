import { Button } from "reactstrap";
import styled from "styled-components";

export const PrimaryButton = styled(Button)`
    background: #58a946 !important;
    border-radius: 8px;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    border: none;
    padding: 13px 30px;
    transition: box-shadow 0.2s ease 0s, transform 0.1s ease 0s !important;
`;
