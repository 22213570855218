import { toast } from "react-toastify";
import { repaymentApiUrl } from "../../constants/APIConstants";
import { UPDATE_LOADING } from "../../redux/modules/loading";
import { createAction } from "../utils";

const GET_PAYMENT_PLAN = "plt-web/paymentPlan/GET_PAYMENT_PLAN";
const EMPTY_PAYMENT_PLAN = "plt-web/paymentPlan/EMPTY_PAYMENT_PLAN";

const initialState = {
    data: {},
    loading: false
};

export default function reducer(state = initialState, { type, ...action }) {
    switch (type) {
        case GET_PAYMENT_PLAN: {
            return { data: action.payload, loading: false };
        }
        case EMPTY_PAYMENT_PLAN: {
            return { data: {}, loading: false };
        }
        default:
            return state;
    }
}

export const emptyPaymentPlan = () => ({
    type: EMPTY_PAYMENT_PLAN
});

export const getPaymentPlan =
    ({ logError, dispatch, sendHttpRequest }) =>
    (params) => {
        const handlePaymentPlanError = (errorMessage, requestParams) => {
            toast.error("Failed to retrieve payment plan: " + errorMessage);
            logError("Error calling " + repaymentApiUrl + ". Error: " + errorMessage + ". Params: " + requestParams);
        };

        dispatch(createAction(UPDATE_LOADING, true));
        return sendHttpRequest(repaymentApiUrl, { method: "POST", body: JSON.stringify(params) })
            .then((response) => response.text())
            .then((textResponse) => JSON.parse(textResponse))
            .then((paymentPlan) => {
                if (paymentPlan.message) {
                    handlePaymentPlanError(paymentPlan.message, JSON.stringify(params));
                }
                dispatch(createAction(UPDATE_LOADING, false));
                dispatch(createAction(GET_PAYMENT_PLAN, paymentPlan));
                return paymentPlan;
            })
            .catch((err) => {
                handlePaymentPlanError(err.message);
                dispatch(createAction(GET_PAYMENT_PLAN, {}));
                return { message: err.message };
            });
    };
