const UPDATE_HOTEL_OFFERS = "plt-web/detailHotel/UPDATE_HOTEL_OFFERS";
const EMPTY_HOTEL_DETAIL = "plt-web/detailHotel/EMPTY_HOTEL_DETAIL";
const SELECT_HOTEL_OFFER = "plt-web/detailHotel/SELECT_HOTEL_OFFER";
const LOADING_HOTEL = "plt-web/detailHotel/LOADING_HOTEL";

const initialState = {
    offers: [],
    loading: false
};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case LOADING_HOTEL: {
            return {
                ...state,
                selectedOfferId: undefined,
                loading: true
            };
        }
        case UPDATE_HOTEL_OFFERS: {
            const { type, ...everythingElse } = action.payload;
            return {
                ...state,
                ...everythingElse,
                offers: action.payload.offers,
                selectedOfferId: undefined,
                loading: false
            };
        }
        case SELECT_HOTEL_OFFER: {
            return {
                ...state,
                selectedOfferId: action.data,
                loading: false
            };
        }
        case EMPTY_HOTEL_DETAIL: {
            return initialState;
        }
        default:
            return state;
    }
}
