import Styled from "@emotion/styled";
import { Box, FormControl, Grid } from "@mui/material";
import { Button } from "reactstrap";
import { ColorTheme } from "../../../styles/color";

export const FlightStyledSearchTab = Styled(Box)`
    display: flex;
    padding: 4px 8px 8px 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    cursor: pointer;
    color: ${(props) => (props.isactive ? ColorTheme.base.white : ColorTheme.primary[500])};
    border-bottom:  2px solid ${(props) => (props.isactive ? ColorTheme.primary[500] : "#E5E7EB")};

    p {
        color: ${(props) => (!props.isactive ? ColorTheme.grayNeutral[800] : ColorTheme.primary[500])} !important;
    }
`;

export const CustomButton = Styled(Button)`
    border-radius: 4px;
    background: ${ColorTheme.grayNeutral[200]};
    display: flex;
    width: 32px;
    height: 32px;
    padding: 4px 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: ${ColorTheme.grayNeutral[800]};
    &:disabled {
        background: ${ColorTheme.grayNeutral[200]};
        color: ${ColorTheme.grayNeutral[400]};
    }
`;

export const CustomItem = Styled(Box)`
    margin-bottom: 14px;
    padding-left: 20px;
    padding-right: 20px;

    & .row {
        align-items: center;
    }
`;

export const StyledSelect = Styled(FormControl)`
    & .MuiFormLabel-root {
        color: #19b4d1;
    }
`;

export const PassengerTextfield = Styled(Box)`
    border: 1px solid ${ColorTheme.grayNeutral[300]};
    display: flex;
    padding: 10px 12px;
    align-items: center;
    gap: 8px;
    height: 55px;
    flex: 1 0 0;
    cursor: pointer;
`;

export const MobileDatepickerContainer = Styled(Grid)`
@media (max-width: 768px) {
    & .MuiInputBase-root {
        border: 0px !important;
        height: 70px;
}
    }    
    
`;
