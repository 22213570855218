import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { emptyPaymentPlan } from "../../../redux/modules/paymentPlan";
import { emptySearchResults } from "../../../redux/modules/search";
import { ColorTheme } from "../../../styles/color";
import HomeCustomerReview from "./HomeCustomerReview";
import HomeFeature from "./HomeFeature";
import HomePopularDestination from "./HomePopularDestination";
import HomepageHeader from "./HomepageHeader";
import { useHome } from "./hook";
import { Promotions, Promotion } from "../promotions/Promotion";
import { IS_AU_SITE, IS_USA_SITE } from "../../../constants";

const Homepage = () => {
    const { selectedLocation, selectedTab, setSelectedLocation, setSelectedTab, dispatch, location } = useHome();
    const searchParams = new URLSearchParams(location.search);

    const redirectToWebflowHomepage =
        window.location.href.includes('staging.app.paylatertravel.ca') ||
        window.location.href.includes('app.paylatertravel.ca') ||
        window.location.href.includes('localhost.ca')
    if (redirectToWebflowHomepage) {
        window.location.assign('https://www.paylatertravel.ca')
        return;
    }

    useEffect(() => {
        searchParams.get("tab") === "flights" && setSelectedTab(1);
        dispatch(emptySearchResults());
        // empties the payment plan data on redux store to prevent a bug where wrong departure and payment start date data being used in the "Review” page even after the customer has selected a different search parameter in the homepage.
        dispatch(emptyPaymentPlan());
    }, [dispatch, location.pathname, setSelectedTab]);

    return (
        <Grid container minHeight={"100vh"}>
            <Promotions>
                <Promotion
                    promotionName={"Singapore Airlines $39 Campaign"}
                    condition={() => IS_AU_SITE}
                    startDateTimeInclusive={"2025-01-08T00:00:01+11:00"}
                    endDateTimeExclusive={"2025-03-20T23:59:59+11:00"}
                    banner1920={"assets/redesign/promotions/singapore-airlines/banner-1920.webp"}
                    banner1440={"assets/redesign/promotions/singapore-airlines/banner-1440.webp"}
                    banner390={"assets/redesign/promotions/singapore-airlines/banner-390.webp"}
                    bannerLink={"https://offers.paylatertravel.com.au/?utm_source=website&utm_medium=homepage_banner&utm_campaign=SQLaunch"}
                    bannerBackgroundColor={"#84AC9F"}
                    bar1920={"assets/redesign/promotions/singapore-airlines/bar-1440.webp"}
                    bar1440={"assets/redesign/promotions/singapore-airlines/bar-1440.webp"}
                    bar390={"assets/redesign/promotions/singapore-airlines/bar-390.webp"}
                    barLink={"https://offers.paylatertravel.com.au/?utm_source=website&utm_medium=announcement_bar&utm_campaign=SQLaunch"}
                    barBackgroundColor={"#005467"}
                />

                 <Promotion
                     promotionName={"Fiji Airways $49 Campaign"}
                     condition={() => IS_USA_SITE}
                     startDateTimeInclusive={"2025-03-01T00:00:00-05:00"}
                     endDateTimeExclusive={"2025-04-15T23:59:59-05:00"}
                     bar1920={"assets/redesign/promotions/fiji-airways/bar-1440.webp"}
                     bar1440={"assets/redesign/promotions/fiji-airways/bar-1440.webp"}
                     bar390={"assets/redesign/promotions/fiji-airways/bar-390.webp"}
                     barLink={"https://www.paylatertravel.com/flightssearch/s/DFW/NAN/2025-06-07/2025-06-14?adults=1&children=0&infants=0&cabinClass=Y"}
                     barBackgroundColor={"rgb(0, 103, 123)"}
                 />
            </Promotions>
            <Grid item xs={12} testid="test100">
                <HomepageHeader
                    selectedLocation={selectedLocation}
                    selectedTab={selectedTab}
                    setSelectedLocation={setSelectedLocation}
                    setSelectedTab={setSelectedTab}
                />
            </Grid>
            <Grid item xs={12} bgcolor={ColorTheme.base.white}>
                <Grid container justifyContent={"center"}>
                    <Grid item px={["20px", "20px", "0px"]} maxWidth={"1140px"}>
                        <HomeFeature />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} id="promotion-banner">{" "}</Grid>
            {/* BANNER CODE -- Filled by the `Promotion` component; `Promotion`s to be added above */}
            <Grid item xs={12}>
                <HomeCustomerReview />
            </Grid>
            <Grid item xs={12} pb={"160px"} bgcolor={ColorTheme.base.white}>
                <Grid container justifyContent={"center"}>
                    <Grid item pt={"80px"} px={["20px", "20px", "0px"]} maxWidth={["100%", "100%", "1140px"]}>
                        <HomePopularDestination />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Homepage;
