import React from "react";
import { useSearchForm } from "./hook";
import { Grid } from "@mui/material";
import FlightSearchForm from "./flightsearch/FlightSearchForm";

const SearchForm = ({ ...props }) => {
    const {
        activeTab,
        addMultiCityLeg,
        auth,
        balance,
        changeSearchType,
        onFlightSearch,
        removeMultiCityLeg,
        searchQuery,
        setSearchQuery,
        updateLocation,
        updateMultiCityDepartureDate,
        updateMultiCityLocation,
        windowWidth,
        setForm
    } = useSearchForm(props);

    const flightsType = searchQuery.flights.type;

    const renderForm = () => {
        if (activeTab === 1) {
            return (
                <FlightSearchForm
                    auth={auth}
                    balance={balance}
                    onFlightSearch={onFlightSearch}
                    searchQuery={searchQuery}
                    setForm={setForm}
                    removeMultiCityLeg={removeMultiCityLeg}
                    setSearchQuery={setSearchQuery}
                    updateLocation={updateLocation}
                    flightsType={flightsType}
                    setFlightType={changeSearchType}
                    addMultiCityLeg={addMultiCityLeg}
                    updateMultiCityDepartureDate={updateMultiCityDepartureDate}
                    updateMultiCityLocation={updateMultiCityLocation}
                    windowWidth={windowWidth}
                />
            );
        }
    };

    return (
        <Grid container width={"100%"}>
            <Grid item xs={12}>
                {renderForm()}
            </Grid>
        </Grid>
    );
};

export default SearchForm;
