import Style from "@emotion/styled";
import { Box } from "@mui/material";
import { ColorTheme } from "../../styles/color";

export const FlightAutocompleteContainer = Style.div`
    & .dropdown-menu {
        top: 50px !important;
    }
    & .btn-close {
        display: none;
    }
`;

export const StyledTab = Style(Box)`
    display: flex;
    padding: 4px 8px 8px 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    cursor: pointer;
    
    color: ${(props) => (props.isactive ? ColorTheme.base.white : props.tabcolor || ColorTheme.primary[500])};
    border-bottom:  2px solid ${(props) => (props.isactive ? props.tabcolor || ColorTheme.primary[500] : "#E5E7EB")};
    
    p {
        color: ${(props) =>
            !props.isactive ? ColorTheme.grayNeutral[800] : props.tabcolor || ColorTheme.primary[500]} !important;
    }
`;

export const PaginationStyledContainer = Style(Box)`
& .MuiButtonBase-root {
    border: 0px;
    color: ${ColorTheme.grayNeutral[400]} !important;
};
& .Mui-selected {
        background: ${ColorTheme.secondary[100]} !important;
        font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;
color: ${ColorTheme.grayNeutral[800]} !important;
    };
`;
