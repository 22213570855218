import { Box } from "@mui/material";
import Styled from "@emotion/styled";
import { createPortal } from "react-dom";
import { createContext, useContext, useEffect, useState } from "react";
import moment from "moment/moment";
import { windowScroll } from "../../../utils/SiteUtils";
import { _url } from "../../../config/utils";

const bannerElementID = "promotion-banner";
const barElementID = "promotion-announcement-bar";

const testEnvironment = (host) => {
    if (process.env.REACT_APP_ENVIRONMENT !== undefined) return process.env.REACT_APP_ENVIRONMENT !== "production";
    return /^localhost(?:\.[a-z]{2})?$|\.staging\..+\.dev$/i.test(host);
};

const Context = createContext({ now: moment() });

export const Promotions = ({ children }) => {
    const testMode = testEnvironment(window.location.hostname);
    const [now, setNow] = useState(moment());
    const [domReady, setDomReady] = useState(false);
    useEffect(() => setDomReady(true), []);
    if (!domReady) return null;

    return (
        <Context.Provider value={{ now }}>
            {testMode && <Widget now={now} onChange={setNow} />}
            {children}
        </Context.Provider>
    );
};

export const Promotion = ({
    promotionName,
    startDateTimeInclusive,
    endDateTimeExclusive,
    condition = () => true,

    banner1920,
    banner1440,
    banner390,
    bannerLink,
    bannerBackgroundColor,

    barBackgroundColor,
    bar1920,
    bar1440,
    bar390,
    barLink,
}) => {
    if (!condition()) return null;
    const promoStartInclusive = moment(startDateTimeInclusive);
    const promoEndExclusive = moment(endDateTimeExclusive);

    console.assert(
        promoStartInclusive.isValid(),
        `Promotion (${promotionName}): invalid start date: ${startDateTimeInclusive}`
    );
    console.assert(
        promoEndExclusive.isValid(),
        `Promotion (${promotionName}): invalid end date: ${startDateTimeInclusive}`
    );
    console.assert(
        promoStartInclusive.isBefore(promoEndExclusive),
        `Promotion (${promotionName}): start date is after end date: ${startDateTimeInclusive} > ${endDateTimeExclusive}`
    );

    const { now } = useContext(Context);
    const [isActive, setActive] = useState(false);
    useEffect(() => {
        setActive(now.isBetween(promoStartInclusive, promoEndExclusive, undefined, "[)"));
    }, [now]);

    const hasBanner = !!banner1920 && !!banner1440 && !!banner390;

    return (
        isActive && (
            <>
                {createPortal(
                    <Bar
                        barBackgroundColor={barBackgroundColor}
                        bar1920={bar1920}
                        bar1440={bar1440}
                        bar390={bar390}
                        barLink={barLink}
                    />,
                    document.getElementById(barElementID)
                )}
                {hasBanner && createPortal(
                    <Banner
                        bannerBackgroundColor={bannerBackgroundColor}
                        banner1920={banner1920}
                        banner1440={banner1440}
                        banner390={banner390}
                        bannerLink={bannerLink}
                    />,
                    document.getElementById(bannerElementID)
                )}
            </>
        )
    );
};

const Bar = ({ barBackgroundColor, bar1920, bar1440, bar390, barLink }) => {
    return (
        <Box
            sx={{
                textAlign: "center",
                cursor: "pointer",
                backgroundColor: barBackgroundColor,
                height: ["52px", "56px", "57px"]
            }}
            onClick={() => {
                if (barLink) window.open(barLink, "_blank");
                else windowScroll(bannerElementID);
            }}
        >
            <picture>
                <source srcSet={_url(bar1920)} media="(min-width: 1200px)" />
                <source srcSet={_url(bar1440)} media="(min-width: 700px)" />
                <img src={_url(bar390)} alt={"Promotion Announcement Bar"} style={{ height: "100%" }} />
            </picture>
        </Box>
    );
};

const Banner = ({ bannerBackgroundColor, banner1920, banner1440, banner390, bannerLink }) => {
    return (
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"} sx={{ width: "100%", backgroundColor: bannerBackgroundColor }}>
            <picture
                onClick={() => {
                    if (bannerLink) window.open(bannerLink, "_blank");
                    else window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                style={{ cursor: "pointer" }}
            >
                <source srcSet={_url(banner1920)} media="(min-width: 1200px)" />
                <source srcSet={_url(banner1440)} media="(min-width: 700px)" />
                <img src={_url(banner390)} alt={"Promotion Banner"} style={{ width: "100%", height: "auto" }} />
            </picture>
        </Box>
    );
};

// ---

const Widget = ({ now, onChange }) => {
    const format = (momentNow) => momentNow.format("YYYY-MM-DDTHH:mm");
    const [localNow, setLocalNow] = useState(now);
    const [inputValue, setInputValue] = useState(format(now));
    useEffect(() => setInputValue(format(localNow)), [localNow]);
    useEffect(() => onChange(localNow), [localNow]);
    const reset = () => setLocalNow(moment());

    return (
        <StyledWidget>
            <div className="content">
                <h1 className="title">Promotion Date Simulator (browser time)</h1>
                <form onReset={reset}>
                    <input
                        type="datetime-local"
                        value={inputValue}
                        onChange={({ target }) => setLocalNow(moment(target.value))}
                    />
                    <button type="reset">Reset</button>
                </form>
            </div>
        </StyledWidget>
    );
};

const StyledWidget = Styled.div`
    position: fixed;
    top: 0.5em;
    right: 0.5em;
    width: 20px;
    height: 20px;
    z-index: 9999;
    background-color: rgb(255 255 255 / 50%);
    border-radius: 0.25em;
    box-shadow: 0 0 0.25em rgb(0 0 0 / 50%);
    border: 1px solid rgb(0 0 0 / 50%);
    backdrop-filter: blur(0.1em);
    display: flex;
    align-items: center;
    justify-content: center;

    * {
        font-family: ui-monospace, monospace;
        font-weight: normal;
        font-size: 12px;
    }
    
    
    .title {
        font-size: 1em;
        font-weight: bold;
    }

    .content {
        visibility: hidden;
        padding: 0.5em;
        display: flex;
        flex-direction: column;
        gap: 0.25em;
        
        form {
            display: flex;
            gap: 0.25em;
            
            input {
                border-radius: 0.25em;
                border: 1px solid gray;
            }
            
            button {
                border-radius: 0.25em;
                background-color: lightgray;
            }
            
        }
    }

    &:hover, &:focus-within {
        width: fit-content;
        height: fit-content;
        background-color: rgb(255 255 255 / 90%);
    
        .content {
            visibility: visible;
        }
    }
`;
