import { toast } from "react-toastify";

import { createAction } from "../utils";
import internalApi from "../../config/internalApi";
import { logoutUser } from "../modules/auth";

export const ADD_RECENTLY_COMPLETED_BOOKING = "plt-web/bookings/ADD_RECENTLY_COMPLETED_BOOKING";
export const EMPTY_BOOKING = "plt-web/bookings/EMPTY_BOOKING";
const GET_BOOKINGS = "plt-web/bookings/GET_BOOKINGS";
const DETAIL_BOOKING = "plt-web/bookings/DETAIL_BOOKING";
const LOADING_BOOKING = "plt-web/bookings/LOADING_BOOKING";
const GET_SUBSCRIPTIONS = "plt-web/bookings/GET_SUBSCRIPTIONS";

const initialState = {
    data: [],
    recentlyCompletedBooking: null,
    loading: false,
    total: 0,
    detail: {},
    subscriptions: []
};

export default function reducer(state = initialState, { type, ...action }) {
    switch (type) {
        case LOADING_BOOKING: {
            return {
                ...state,
                data: [],
                detail: {},
                loading: true
            };
        }
        case ADD_RECENTLY_COMPLETED_BOOKING: {
            return {
                ...state,
                recentlyCompletedBooking: action.data
            };
        }
        case EMPTY_BOOKING: {
            return {
                ...state,
                recentlyCompletedBooking: null
            };
        }
        case GET_BOOKINGS: {
            return {
                ...state,
                data: action.payload,
                detail: {},
                loading: false
            };
        }
        case DETAIL_BOOKING: {
            return {
                ...state,
                detail: action.payload,
                loading: false
            };
        }
        case GET_SUBSCRIPTIONS: {
            return { ...state, subscriptions: action.payload, loading: false };
        }
        default:
            return state;
    }
}

export const getBookings = () => (dispatch) => {
    dispatch(createAction(LOADING_BOOKING));
    internalApi
        .get("v2/customer/me/booking", {})
        .then((response) => {
            dispatch(createAction(GET_BOOKINGS, response));
        })
        .catch((err) => {
            if(err.response){
                toast.error(err?.response?.data?.message || `Error status ${err.response.status}`);
                if (err.response.status === 401) {
                    dispatch(logoutUser());
                }
            } else {
                console.error('Error listing bookings from Dashboard', err);
            }
        });
};

export const getBookingByReference = (reference) => (dispatch) => {
    dispatch(createAction(LOADING_BOOKING));
    internalApi
        .get(`v2/customer/me/booking/${reference}`, {})
        .then((response) => {
            dispatch(createAction(DETAIL_BOOKING, response));
        })
        .catch((err) => {
            console.log(err);
        });
};
