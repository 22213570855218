import "bootstrap/dist/css/bootstrap.min.css";
import "./bootstrap-overrides.css"
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import "tippy.js/animations/shift-away.css";
import "tippy.js/dist/tippy.css";
import { BASE_PATH } from "./config";
import { getStoreInstance } from "./store/ConfigureStore";
import "./lang/i18n";
import "./styles/index.scss";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "react-toastify/dist/ReactToastify.css";
import { MUI_LICENSE_KEY } from "./constants";
import { LicenseInfo } from "@mui/x-license-pro";
import AppRoute from "./containers/route/AppRoute";
import mainRedirect from "./mainRedirect";

LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);

mainRedirect(location);

const { store, persistor } = getStoreInstance();

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter basename={BASE_PATH}>
                <AppRoute />
            </BrowserRouter>
        </PersistGate>
    </Provider>,
    document.getElementById("root")
);
