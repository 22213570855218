import i18next from "i18next";
import { getLang } from "../constants";
import { AU_CONSTANT } from "./AUConstants";
import { US_CONSTANT } from "./USConstants";
import { CA_CONSTANT } from "./CAConstants";

export const getAddDays = (days) => {
    const result = new Date();
    result.setDate(result.getDate() + days);
    return result;
};

export const capitalizeFirstLetterForEachWord = (string) =>
    string.toLowerCase().replace(/\b\w/g, function (char) {
        return char.toUpperCase();
    });

export const windowScroll = (id) => {
    window.scrollTo({
        top: document.getElementById(id).getBoundingClientRect().top + window.scrollY - (80 + 68),
        behavior: "smooth"
    });
};

export const formatPhoneNumber = (phoneNumber) => {
    return getSiteValueByLang("formatPhoneNumber")(phoneNumber)
};

export const getScrollPosition = (className) => {
    const rdrRoundEdgeElement = document.querySelector(className);
    const rect = rdrRoundEdgeElement.getBoundingClientRect();
    const distanceFromTop = rect.top + window.scrollY - 200;
    return distanceFromTop;
};

export const updateSiteLang = () => {
    const lang = getLang();
    i18next.changeLanguage(lang);
};

const siteValueConstant = {
    'en-US': US_CONSTANT,
    'en-AU': AU_CONSTANT,
    'en-CA': CA_CONSTANT,
};

export const getSiteValueByLang = (key, value) => {
    const lang = getLang();
    return value ? siteValueConstant[lang][key](value) : siteValueConstant[lang][key];
};
