import axios from "axios";

export const countrySites = {
    US: "paylatertravel.com",
    AU: "paylatertravel.com.au",
    CA: "paylatertravel.ca"
};

export const getGeoInfo = async () => {
    return axios
        .get("https://ipapi.co/json/")
        .then((response) => {
            const { data } = response;
            const { country_code: countryCode, country_name: countryName } = data;
            const hostname = window.location.hostname;

            if (!countrySites[countryCode]) return;
            if (hostname.includes('localhost')) return;
            if (hostname.includes(countrySites[countryCode])) { return };
            return Promise.resolve({
                countryCode,
                countryName,
                countryModal: true
            });
        })
        .catch((error) => {
            console.log(error);
        });
};
