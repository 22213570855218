import Styled from "@emotion/styled";
import { Box, MenuItem, TextField } from "@mui/material";
import { FormGroup } from "reactstrap";
import { ColorTheme } from "../../../styles/color";

export const StyledTextField = Styled(FormGroup)`
  input {
    height: 38px;
    

    ${(props) =>
        props.redesign &&
        `
    height: 52px;
    border-radius: var(--XS, 4px);
    border: 1px solid var(--Gray-300, #D0D5DD);
    background: var(--Base-White, #FFF);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    `}
    
  }
  & .form-control:focus {
    ${(props) =>
        props.redesign &&
        `
        border: 1px solid ${ColorTheme.secondary[400]}!important;
    `};
  }
  
  & .special-label {
    display: none;
  }
 `;

export const StyledSelectFormFroup = Styled(FormGroup)`
  select {
    ${(props) =>
        props.redesign &&
        `
    height: 52px;
    border-radius: 4px;
    border-radius:  4px;
    border: 1px solid ${ColorTheme.grayNeutral[300]};
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    `};
    
  }
  & .form-select:focus {
    ${(props) =>
        props.redesign &&
        `
        border: 1px solid ${ColorTheme.secondary[400]}!important;
    box-shadow: none !important;   
    `};
 
  }
  & .form-select:focus-visible {
    ${(props) =>
        props.redesign &&
        `
        border: 1px solid ${ColorTheme.secondary[400]}!important;
    `};
  }
 `;

export const SearchTextFieldStyle = Styled(TextField)`
    & .MuiInputBase-root {
        border-radius: 4px;
    border: 1px solid var(--gray-300, #D2D6DB);
    height: 56px;
    }

    & .MuiButtonBase-root {
        position: absolute;
        right: 0px;
    }
    input {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }
    fieldset {
        display: none;
    }
`;

export const StyledMenuItem = Styled(MenuItem)`
    background-color: ${(props) => (props.isactive ? "#E9F8FB" : ColorTheme.base.white)} !important;
    width: 266px;
    height: 48px;
`;

export const LabelTextfieldContainer = Styled(Box)`
    & .MuiInputBase-root {
        height: 52px;
    }
`;
