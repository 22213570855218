import { Grid } from "@mui/material";
import React from "react";
import { ColorTheme } from "../../../../styles/color";
import FlightMultiCityForm from "./FlightMultiCityForm";
import FlightOneWayForm from "./FlightOneWayForm";
import FlightReturnForm from "./FlightReturnForm";
import FlightSearchTab from "./FlightSearchTab";

const FlightSearchForm = ({
    flightsType,
    setFlightType,
    auth,
    balance,
    onFlightSearch,
    searchQuery,
    setForm,
    setSearchQuery,
    updateLocation,
    removeMultiCityLeg,
    addMultiCityLeg,
    updateMultiCityDepartureDate,
    updateMultiCityLocation,
    windowWidth
}) => {
    const renderSearchForm = () => {
        if (flightsType === "return") {
            return (
                <FlightReturnForm
                    auth={auth}
                    balance={balance}
                    onFlightSearch={onFlightSearch}
                    searchQuery={searchQuery}
                    setForm={setForm}
                    setSearchQuery={setSearchQuery}
                    updateLocation={updateLocation}
                />
            );
        }
        if (flightsType === "one-way") {
            return (
                <FlightOneWayForm
                    auth={auth}
                    balance={balance}
                    onFlightSearch={onFlightSearch}
                    searchQuery={searchQuery}
                    setForm={setForm}
                    setSearchQuery={setSearchQuery}
                    updateLocation={updateLocation}
                />
            );
        }
        return (
            <FlightMultiCityForm
                onFlightSearch={onFlightSearch}
                removeMultiCityLeg={removeMultiCityLeg}
                searchQuery={searchQuery}
                setForm={setForm}
                addMultiCityLeg={addMultiCityLeg}
                updateMultiCityDepartureDate={updateMultiCityDepartureDate}
                setSearchQuery={setSearchQuery}
                updateMultiCityLocation={updateMultiCityLocation}
                windowWidth={windowWidth}
            />
        );
    };
    return (
        <Grid
            container
            bgcolor={ColorTheme.base.white}
            borderRadius={"24px 24px 24px 24px"}
        >
            <Grid item xs={12}>
                <FlightSearchTab flightsType={flightsType} setFlightType={setFlightType} />
            </Grid>
            <Grid item xs={12}>
                {renderSearchForm()}
            </Grid>
        </Grid>
    );
};

export default FlightSearchForm;
