/* eslint-disable no-unreachable */
import { Box, Grid } from "@mui/material";
import React from "react";
import FlightAutocompleteSearch from "../../../../components/common/FlightAutocompleteSearch";

import moment from "moment";
import { PrimaryButton } from "../../../../components/common/button/ButtonStyle";
import MuiSingleDatePicker from "../../../../components/common/datepicker/redesign/MuiSingleDatePicker";
import { useGetWindowSize } from "../../../../components/common/hook/WindowSize";
import { _url } from "../../../../config/utils";
import { FLIGHT_SEARCH_MIN_DAYS_FROM_TODAY } from "../../../../constants";
import FlightSearchTextField from "../mobile/FlightSearchTextField";
import FlightPassengerDropdown from "./FlightPassengerDropdown";

const FlightOneWayForm = ({
    auth,
    searchQuery,
    balance,
    updateLocation,
    setSearchQuery,
    onFlightSearch,
    setForm
}) => {
    const flightsQuery = searchQuery.flights;
    const { isAuthenticated } = auth;
    const { mobileView } = useGetWindowSize();

    const renderFlightPassengerForm = (query) => (
        <Box mt={["10px", "10px", "0px"]}>
            <FlightPassengerDropdown
                adults={query.adults}
                child={query.children}
                infants={query.infants}
                cabinClass={query.cabinClass || "Y"}
                onChangePassenger={(e) => {
                    setSearchQuery((prev) => ({
                        ...prev,
                        flights: {
                            ...query,
                            adults: e.adults,
                            children: e.child,
                            infants: e.infants,
                            cabinClass: e.cabinClass
                        }
                    }));
                    setForm((prev) => ({
                        ...prev,
                        adults: {
                            isTouched: true
                        }
                    }));
                }}
                onChangeAdults={(e) => {
                    setSearchQuery((prev) => ({
                        ...prev,
                        flights: {
                            ...query,
                            adults: e
                        }
                    }));
                    setForm((prev) => ({
                        ...prev,
                        adults: {
                            isTouched: true
                        }
                    }));
                }}
                onChangeChildren={(e) => {
                    setSearchQuery((prev) => ({
                        ...prev,
                        flights: {
                            ...query,
                            children: e
                        }
                    }));
                }}
                onChangeInfants={(e) => {
                    setSearchQuery((prev) => ({
                        ...prev,
                        flights: {
                            ...query,
                            infants: e
                        }
                    }));
                }}
            />
        </Box>
    );

    const renderAutoCompleteSearch = () => {
        if (mobileView) {
            return (
                <FlightSearchTextField
                    destination={flightsQuery.destination}
                    leaving={flightsQuery.origin}
                    id={"Leaving"}
                    placeholderDestination={"Going to"}
                    placeholderLeaving={"Leaving from"}
                    updateDestination={({ field, location }) => {
                        updateLocation({ field, location });
                    }}
                    updateLeavingFrom={({ field, location }) => {
                        updateLocation({ field, location });
                    }}
                />
            );
        }

        return (
            <Grid container gap={"5px"} alignItems={"center"} flexWrap={"nowrap"}>
                <Grid item width={"47%"}>
                    <FlightAutocompleteSearch
                        id="origin"
                        placeholder="Leaving from"
                        fieldName="origin"
                        startIcon={_url("assets/redesign/icons/flight_depart.svg")}
                        updateLocation={({ field, location }) => {
                            updateLocation({ field, location });
                        }}
                        selected={flightsQuery.origin ? [flightsQuery.origin] : null}
                    />
                </Grid>
                <Grid item width={"6%"} display={"flex"} justifyContent={"center"}>
                    <img
                        onClick={() => {
                            updateLocation({
                                field: "origin",
                                location: flightsQuery.destination
                            });
                            updateLocation({
                                field: "destination",
                                location: flightsQuery.origin
                            });
                        }}
                        style={{ cursor: "pointer" }}
                        src={_url("assets/redesign/icons/arrow-left-right.svg")}
                    />
                </Grid>
                <Grid item width={"47%"}>
                    <FlightAutocompleteSearch
                        id="destination"
                        fieldName="destination"
                        startIcon={_url("assets/redesign/icons/flight_land.svg")}
                        placeholder="Going to"
                        updateLocation={({ field, location }) => {
                            updateLocation({ field, location });
                        }}
                        selected={flightsQuery.destination ? [flightsQuery.destination] : null}
                    />
                </Grid>
            </Grid>
        );
    };

    return (
        <Grid container columnSpacing={"8px"} px={"16px"} py={"24px"}>
            <Grid item xs={12} md={6}>
                {renderAutoCompleteSearch()}
            </Grid>
            <Grid item xs={12} md={3} mt={["12px", "12px", "0px"]}>
                <MuiSingleDatePicker
                    date={flightsQuery.departureDate ? moment(flightsQuery.departureDate) : null}
                    handleDateChange={(startDate) => {
                        setSearchQuery((prev) => ({
                            ...prev,
                            flights: {
                                ...prev.flights,
                                departureDate: startDate ? moment(startDate).format("YYYY-MM-DD") : null,
                                returnDate: null
                            }
                        }));
                    }}
                    maximumDate={moment().add("11", "months")}
                    minimumDate={
                        isAuthenticated && balance > 0
                            ? moment().add("3", "days")
                            : moment().add(FLIGHT_SEARCH_MIN_DAYS_FROM_TODAY, "days")
                    }
                />
            </Grid>
            <Grid item xs={12} md={3}>
                {renderFlightPassengerForm(flightsQuery)}
            </Grid>
            <Grid item xs={12} mt={"20px"}>
                <Grid container justifyContent={"flex-end"}>
                    <Grid item xs={12} md={3}>
                        <PrimaryButton fullWidth onClick={onFlightSearch}>
                            Search flights
                        </PrimaryButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default FlightOneWayForm;
