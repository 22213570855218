import { deriveAPIUrlFromCurrentLocation, deriveBookingManagementAPIUrlFromCurrentLocation } from "./deriveAPIUrlFromCurrentLocation";

const KEY_PERSIST_STORE = 'fight-booking-app';
const API_URL = deriveAPIUrlFromCurrentLocation(window.location);
const BOOKING_MANAGEMENT_API_URL = deriveBookingManagementAPIUrlFromCurrentLocation(window.location);
const BASE_PATH = process.env.REACT_APP_BASE_PATH;

export {
  KEY_PERSIST_STORE,
  API_URL,
  BOOKING_MANAGEMENT_API_URL,
  BASE_PATH
};
