import { createAction } from "../utils";
import internalApi from "../../config/internalApi";

const GET_REFERRALS = "plt-web/auth/GET_REFERRALS";
export const REMOVE_REFERRALS = "plt-web/auth/REMOVE_REFERRALS";

const initialState = {
    balance: null,
    data: []
};

export default function reducer(state = initialState, { type, ...action }) {
    switch (type) {
        case GET_REFERRALS: {
            return {
                ...state,
                ...action.payload
            };
        }
        case REMOVE_REFERRALS: {
            return initialState;
        }
        default:
            return state;
    }
}

export const getReferrals = (productType) => (dispatch) => {
    internalApi.get("/v2/customer/me/referrals", { productType }).then((response) => {
        dispatch(createAction(GET_REFERRALS, response));
    });
};
